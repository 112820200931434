/* eslint-disable jsx-a11y/alt-text */
import { forwardRef } from "react";
import { getS3URL } from "shared/utils/aws";


export const Image = forwardRef(({ src = "", ...rest }, ref) => (
  <img ref={ref} src={getS3URL(src)} {...rest} />
));
export const Video = forwardRef(({ src = "", ...rest }, ref) => (
  <video ref={ref} src={getS3URL(src)} {...rest} />
));
export const Audio = forwardRef(({ src = "", ...rest }, ref) => (
  <audio ref={ref} src={getS3URL(src)} {...rest} />
));
