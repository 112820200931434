import React, { createContext, useContext, useEffect, useState } from "react";
import { ListSubheader, MenuItem, Select } from "@mui/material";
import styles from "./Dropdown.module.css";
import { forwardRef } from "react";
import { Box } from "shared/Components";
import { useRef } from "react";
import { isFunc } from "shared/utils";
import { ChevronDownIcon, MagnifyingGlassIcon } from "userlove-icons";

const ctx = createContext();

const DropdownItem = ({ children, ...rest }) => {
    const { size } = useContext(ctx);

    return (
        <MenuItem className={`${size === "small" ? styles["small-menu-item"] : ""}`} {...rest}>
            {children}
        </MenuItem>
    );
};

const initialOrigin = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
};

export const Dropdown = forwardRef(
    (
        {
            // required
            children,
            // optional
            size = "medium", // small, medium
            // autoWidth = true,
            fullWidth = true,
            outlined = true,
            className = "",
            border = "",
            height = "",
            backgroundColor: bgColor = "var(--white-color)",
            inValid = false,
            valid = false,
            label,
            helperText,
            dropdownClassName = "",
            renderValue,
            labelClassName,
            selectClassName,
            require = false,
            menuAutoFocus = false,
            popOverClassName,
            IconComponent = ChevronDownIcon,
            menuprops = {},
            allValue = "all",
            ...rest
        },
        ref,
    ) => {
        const backgroundColor = outlined ? bgColor : "var(--gray-bk-color)";

        const inValidClassName = inValid ? styles.error : "";
        const validClassName = valid ? styles.success : "";
        const boxRef = useRef(null);
        const [maxHeight, setMaxHeight] = useState(250);
        const [origin, setOrigin] = useState(initialOrigin);
        useEffect(() => {
            const handleResize = () => {
                if (boxRef.current) {
                    let { bottom } = boxRef.current.getBoundingClientRect();
                    let availableHeight = window.innerHeight - bottom - 20;
                    if (availableHeight < 250 && availableHeight > 180) {
                        setMaxHeight(availableHeight);
                    } else {
                        if (availableHeight < 180) {
                            setOrigin({
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            });
                        } else {
                            setOrigin(initialOrigin);
                        }
                        setMaxHeight(250);
                    }
                }
            };
            handleResize();
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        return (
            <Box
                ref={boxRef}
                className={`${styles.wrapper} ${inValidClassName} ${validClassName} ${className} ${styles["gs-simple-dropdown"]}`}
            >
                {label && (
                    <label className={`${styles.label} ${labelClassName}`}>
                        {label}
                        {require && <span style={{ color: "var(--danger-color)" }}>{"*"}</span>}
                    </label>
                )}
                <ctx.Provider value={{ size }}>
                    <Select
                        // autoWidth={autoWidth}
                        ref={ref}
                        variant="outlined"
                        size={size}
                        fullWidth={fullWidth}
                        style={{
                            backgroundColor,
                            border,
                            height,
                        }}
                        className={`${styles["gs-dropdown-select"]} ${dropdownClassName} ${selectClassName} `}
                        classes={{
                            select: styles.select,
                            icon: styles.icon,
                        }}
                        IconComponent={IconComponent}
                        MenuProps={{
                            className: `${styles["gs-dropdown-popover-menu"]} ${popOverClassName}`,
                            autoFocus: menuAutoFocus,
                            PaperProps: {
                                sx: {
                                    "& ul": {
                                        maxHeight,
                                    },
                                },
                            },
                            ...origin,
                            ...menuprops,
                        }}
                        renderValue={renderValue}
                        {...rest}
                    >
                        {rest?.allOption ? (
                            <Dropdown.Item value={allValue}>All</Dropdown.Item>
                        ) : null}
                        {children}
                    </Select>
                </ctx.Provider>
                {!!helperText && (
                    <div className={styles["helper-text"]}>
                        {/* <InfoOutlinedIcon /> */}
                        <p>{helperText}</p>
                    </div>
                )}
            </Box>
        );
    },
);

export const SearchableDropdown = forwardRef((props, ref) => {
    const [search, setSearch] = useState("");

    if (!isFunc(props.children)) throw Error("Children has to be a function.");

    const handleChange = (e) => setSearch(e.target.value);

    const searchInputRef = useRef(null);

    return (
        <Dropdown
            onOpen={() => {
                setTimeout(() => {
                    searchInputRef?.current?.focus();
                }, 500);
            }}
            onClose={() => {
                setTimeout(() => {
                    setSearch("");
                }, 100);
            }}
            {...props}
            ref={ref}
        >
            <ListSubheader>
                <div className={styles["gs-dropdown-search-input"]}>
                    <MagnifyingGlassIcon width={"14px"} />
                    <input
                        ref={searchInputRef}
                        onChange={handleChange}
                        value={search}
                        placeholder={props.searchInputPlaceholder}
                        onKeyDown={(e) => e.stopPropagation()}
                        type="search"
                    />
                </div>
            </ListSubheader>
            {props?.allOption ? <Dropdown.Item value="All">All</Dropdown.Item> : null}
            {props.children({ search })}
        </Dropdown>
    );
});

Dropdown.Item = DropdownItem;
