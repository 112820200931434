import { deleteFileApi, uploadFileApi } from "./interaction";

// const generateFileName = (name = null) => {
//     let timestamp = new Date().toISOString().replace(/[-:.]/g, "");
//     let random = ("" + Math.random()).substring(2, 8);
//     if (name) {
//         return name + "_" + timestamp + random;
//     } else {
//         return timestamp + random;
//     }
// };

/**
 * Base URL for the folder location on S3.
 * @type {string}
 */
export const folderLocation = process.env.REACT_APP_AWS_PREFIX;

export const getS3URL = (path) => {
  if (path) {
    if (path.includes("http")) {
      return path;
    } else {
      return `${folderLocation}${path}`;
    }
  }
  return "";
};

export const s3Features = {
  //templates
  templates: "template",

  // user & account related
  account: "publicImage",
  // country: "country",
};

export const generateAWSPath = (params) =>
  params.length ? params.join("/") : "";

export const getAcceptedFileFormat = (format) => {
  const fileFormats = {
    images: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/svg",
    ],
    csv: ["text/csv"],
    audio: ["audio/mpeg", "audio/wav", "audio/mp3", "audio/ogg"],
  };
  return fileFormats[format];
};

export const imageUploadToAWS = async (file, fileKey, isAssets = true) => {
  try {
    if (!file) return { status: false, message: "File is Required" };
    const allowedImageTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
    ];
    if (!allowedImageTypes.includes(file.type)) {
      return { status: false, message: "File type not allowed" };
    }
    let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    if (sizeInMB > 2) {
      return { status: false, message: "Image should be less than 2MB" };
    }
    const formData = new FormData();
    formData.append("path_key", fileKey);
    formData.append("file", file);
    formData.append("is_assets", isAssets);

    const response = await uploadFileApi(formData);

    if (response.status !== 200) {
      return {
        status: false,
        message: response.data.message || "Error From AWS S3",
      };
    } else {
      return {
        status: true,
        originalName: file?.name,
        data: response.data,
      };
    }
  } catch (error) {
    return {
      status: false,
      message: error?.message,
    };
  }
};

export const uploadFileOnAWS = async (file, fileKey, isAssets = true) => {
  try {
    const formData = new FormData();
    formData.append("path_key", fileKey);
    formData.append("file", file);
    formData.append("is_assets", isAssets);

    const res = await uploadFileApi(formData);

    if (res?.status) {
      return res?.data;
    } else {
      throw res;
    }
  } catch (e) {
    console.log("error", e);
    throw e;
  }
};

export const deleteImageFromAWS = async (src) => {
  try {
    const response = await deleteFileApi({ src });

    if (!response || !response.status) {
      return {
        status: false,
        message: response?.message || "Error From AWS S3",
      };
    } else {
      return {
        status: true,
        data: response.data,
        message: "Success",
      };
    }
  } catch (error) {
    return {
      status: false,
      message: error?.message,
    };
  }
};

const acceptedFilesFormat = getAcceptedFileFormat("images");
const acceptedFilesArray = Array.isArray(acceptedFilesFormat)
  ? acceptedFilesFormat
  : acceptedFilesFormat.split(",");
export const uploadImageWithValidation = async (
  file,
  path,
  noSizeValidate,
  acceptedFiles = []
) => {
  const mergeAcceptedFiles = [...acceptedFiles, ...acceptedFilesArray];
  const fileType = file?.type;
  try {
    if (!file || !path)
      return { status: false, message: "File & Path is Required" };
    const isSupported = mergeAcceptedFiles.some((type) => {
      if (type.endsWith("/*")) {
        const category = type.split("/")[0];
        return fileType.startsWith(category + "/");
      } else {
        return fileType === type;
      }
    });
    if (isSupported) {
      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (!noSizeValidate && sizeInMB > 2) {
        return { status: false, message: "Image should be less than 2MB" };
      }

      const url = await uploadFileOnAWS(file, path);

      return {
        status: true,
        originalName: file?.name,
        data: {
          Location: url,
        },
      };
    } else {
      return {
        status: false,
        message: "Image Should be jpg, png format",
      };
    }
  } catch (error) {
    console.log("error", error);
    return {
      status: false,
      message: error?.message,
    };
  }
};
