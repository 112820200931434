import React, { useContext, useState } from "react";
import { Popover } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { getDateRangeText } from "shared/utils";
// import { ProductContext } from "Product/Context";
import styles from "./DateRangePicker.module.css";
import { CalenderIcon } from "userlove-icons";
import { ProductContext } from "App";
import { dateRanges } from "shared/Hooks/useDateRange";

const dayNames = {
    su: "sun",
    mo: "mon",
    tu: "tue",
    we: "wed",
    th: "thu",
    fr: "fri",
    sa: "sat",
};

const DateRangePicker = ({
    value = [null, null],
    onRangeChange = () => {},
    onTypeChange,
    selected,
    isCustom,
    isCommentCalendar = false,
    className,
}) => {
    const { setSelectedDateRangeLabel } = useContext(ProductContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const rangeText = getDateRangeText(...value);

    const handleDateRangeClick = ({ id, label, ...rest }) => {
        setSelectedDateRangeLabel(label);
        onTypeChange({ id, label, ...rest });
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                }}
                classes={{
                    paper: styles.popover,
                }}
            >
                {!!dateRanges().length && (
                    <div className={styles.menu}>
                        {dateRanges()?.map(({ id, label, ...rest }) => {
                            return (
                                <div
                                    onClick={() => handleDateRangeClick({ id, label, ...rest })}
                                    key={id}
                                    className={`${styles["menu-item"]} ${
                                        id === selected?.id ? styles.active : ""
                                    }`}
                                >
                                    {label}
                                </div>
                            );
                        })}
                    </div>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        showToolbar={false}
                        calendars={1}
                        value={value}
                        disableFuture
                        onChange={onRangeChange}
                        dayOfWeekFormatter={(day) =>
                            dayNames[day.substring(0, 2).toLowerCase()]?.toUpperCase()
                        }
                        renderInput={() => <></>}
                        className={styles.picker}
                    />
                </LocalizationProvider>
            </Popover>
            {isCommentCalendar ? (
                <CalenderIcon
                    height={"18px"}
                    className="secondary-light-icon"
                    width={"18px"}
                    style={{ cursor: "pointer" }}
                    onClick={handleClick}
                />
            ) : (
                <button
                    style={{ width: "100%" }}
                    className={`${className} ${styles.wrapper}`}
                    onClick={handleClick}
                >
                    <span className={styles["range-text"]}>
                        {isCustom ? "Custom Range" : selected?.label}
                    </span>
                    {!!rangeText && <span className={styles["date-text"]}>{rangeText}</span>}
                </button>
            )}
        </React.Fragment>
    );
};

export default DateRangePicker;
