import React from "react";
import styles from "./Banners.module.css";
import { BlueTick } from "../../Assets/Icons";
import { useNavigate } from "react-router-dom";
import { Image } from "../S3Assets";
export const LoginBanner = () => {
    const navigate = useNavigate();
    const data = [
        {
            id: 1,
            icon: <BlueTick width="30px" height="30px" />,
            title: "Onboarding ",
            description: "Easily create engaging onboarding experiences in minutes.",
        },
        {
            icon: <BlueTick width="30px" height="30px" />,
            id: 2,
            title: "Adoption",
            description:
                "Unlock full potential of your product & enable users to get most out of it.",
        },
        {
            icon: <BlueTick width="30px" height="30px" />,
            id: 3,
            title: "Engagement ",
            description: "Maximize product usage, customer satisfaction, and retention.",
        },
    ];
    return (
        <>
            <div className={styles["gs-banner-main-wrapper"]}>
                <div className={styles["gs-banner-top"]}>
                    <img
                        className={styles["gs-banner-log-image"]}
                        height={"45px"}
                        // style={{ margin: "25px 0 0 25px" }}
                        alt="00"
                        src="/image/loginLogo.png"
                        onClick={() => {
                            navigate("/login");
                        }}
                    />
                </div>
                <div className={styles["gs-banner-wrapper"]}>
                    {/* <div className={styles["gs-banner-content"]}> */}
                    {data.map((item, index) => {
                        return (
                            <div key={index} className={styles["contain-box"]}>
                                {item.icon}
                                <div className={styles["text-container"]}>
                                    <p>{item.title}</p>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        );
                    })}
                    {/* </div>     */}
                </div>
            </div>
            <div className={styles["gs-banner-version"]}>
                Version: {process.env.REACT_APP_VERSION}
            </div>
        </>
    );
};
// export const LoginBanner = () => {
//   return (
//     <div className={styles["gs-banner-wrapper"]}>
//       {/* <div className="gs-banner-overlay"> */}
//       <div className={styles["gs-banner-content"]}>
//       <h2 className={styles["header-text"]}>Create personalized onboarding experience
// for your customers</h2>
//       </div>
//       <Image
//         src="/image/LoginBannerNew.png"
//         className={styles["login-bannner-img"]}
//         alt="banner-img"
//         height={500}
//       />
//       {/* </div> */}
//       <p style={{fontSize:"15px"}}>
//       Unified user experience platform for increase engagement, retention and conversions rates         </p>
//     </div>
//   );
// };
// export const LoginBanner = () => {
//   return (
//     <div className={styles["gs-banner-wrapper"]}>
//       {/* <div className="gs-banner-overlay"> */}
//       <div className={styles["gs-banner-content"]}>
//         <h1>Connecting user to product</h1>
//         <p style={{fontSize:"15px"}}>
//         By providing personalized user onboarding, engagement & adoption experience
//         </p>
//         <h2>Connect - Engage - Win</h2>
//       </div>
//       <Image
//         src="/image/Loginimage.svg"
//         className={styles["login-bannner-img"]}
//         alt="banner-img"
//       />
//       {/* </div> */}
//     </div>
//   );
// };

// import React from "react";
// import styles from "./Banners.module.css";

// export const LoginBanner = () => {
//   return (
//     <div className={styles["gs-banner-wrapper"]}>
//       {/* <div className="gs-banner-overlay"> */}
//       <div className={styles["gs-banner-content"]}>
//         <h1>Connecting user to product</h1>
//         <p style={{fontSize:"15px"}}>
//         By providing personalized user onboarding, engagement & adoption experience
//         </p>
//         <h2>Connect - Engage - Win</h2>
//       </div>
//       <Image
//         src="/image/Loginimage.svg"
//         className={styles["login-bannner-img"]}
//         alt="banner-img"
//       />
//       {/* </div> */}
//     </div>
//   );
// };
