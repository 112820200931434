import React from "react";
import s from "./NoData.module.css";
import { Image } from "../S3Assets";
export const NoData = () => {
    return (
        <div className={s["gs-no-data-img"]}>
            <img alt="" src="/image/NoData.svg" />
            {/* <div className="gs-no-data-text-wrap">
        <h2>No Data Found</h2>
        <h3>
          oops! We don’t have the data that you are looking for. Please add one.
        </h3>
      </div> */}
        </div>
    );
};
