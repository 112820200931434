// /* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Button, Avatar, Popover, Dropdown } from "..";
import Styles from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";

import { useCommonFunction } from "shared/Hooks";
import { clearCookie, getCookie } from "shared/Assets/JS/Cookies";
import axios from "axios";
import { useLogin } from "modules/Authentication/hooks/UseLogin";
import { ArrowRightFromBracketIcon, ChevronDownIcon, ChevronUpIcon } from "userlove-icons";
import { Image } from "../S3Assets";
export const ProfilePanel = ({
    anchorEl,
    handleClick,
    handleClose,
    buttonObj,
    // handleLogoutSubmit,
    roles,
    handleRoleChange,
    // getAccountByTokenAccountId,
}) => {
    const { userData } = useLogin();
    const { AxiosUrl } = useCommonFunction();
    const access_token = getCookie("admin_access_token");
    const handleLogoutSubmit = async () => {
        await axios
            .delete(`${AxiosUrl}/user/logout`, {
                headers: {
                    Authorization: access_token,
                    "sub-domain": getCookie("admin_sub_domain"),
                },
            })
            .then(() => {
                sessionStorage.clear();
                clearCookie();
                let currentHost = process.env.REACT_APP_HOST;
                // currentHost = currentHost.substring(14);
                window.location.host = currentHost;
                navigate("/login");
                // window.location.reload(true);
            });
    };

    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <Button className={Styles["gs-profile-panel-button"]} onClick={handleClick}>
                <Avatar
                    // alt="Remy Sharp"
                    // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${userData?.profile_picture}`}
                    // src="/image/svg/UserloveTextLogo.svg"
                    sx={{
                        width: 33,
                        height: 33,
                        backgroundColor:
                            userData?.profile_picture === null ? "var(--primary-color) " : "none",
                        color: "var(--white-color)",
                        fontSize: "14px",
                    }}
                    height={"auto"}
                    className="gs-avatar-profile-panel"
                    variant="rounded"
                >
                    {userData?.profile_picture !== null ? (
                        <Image
                            className="gs-image-userData-panel"
                            height={"auto"}
                            width={"34px"}
                            src={userData?.profile_picture}
                            alt=""
                        />
                    ) : (
                        <>{`${userData?.account_name?.charAt(0)}`}</>
                    )}
                </Avatar>
                <div className={Styles["gs-profile-text-wrapper"]}>
                    <span className={Styles["gs-profile-name"]}>{userData?.account_name}</span>
                    <span className={Styles["gs-profile-company-name"]}>{userData?.email_id}</span>
                </div>
                {open ? (
                    <ChevronUpIcon className="secondary-light-icon" />
                ) : (
                    <ChevronDownIcon className="secondary-light-icon" />
                )}
                {/* <AiFillCaretDown className={Styles["gs-profil-down-arrow-icons"]} /> */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    className={Styles["gs-popover"]}
                >
                    <div className={Styles["gs-popover-wrapper"]}>
                        <div className={Styles["gs-avtar-wrapper"]}>
                            <Avatar
                                // alt="Remy Sharp"
                                // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${userData?.profile_picture}`}
                                // src="/image/svg/UserloveTextLogo.svg"
                                sx={{
                                    width: 34,
                                    height: 34,
                                    textTransform: "uppercase",
                                    backgroundColor:
                                        userData?.profile_picture === null
                                            ? "var(--primary-color) "
                                            : "none",
                                    // backgroundColor:
                                    //     getAccountByTokenAccountId?.getAccountByTokenAccountId?.data
                                    //         ?.logo === null
                                    //         ? "var(--primary-color) "
                                    //         : "var(--white-color)",
                                    color: "var(--white-color)",
                                    fontSize: "14px",
                                }}
                                height={"auto"}
                                className="gs-avatar-profile-panel"
                                variant="rounded"
                            >
                                {userData?.profile_picture !== null ? (
                                    <Image
                                        height={"auto"}
                                        width={"34px"}
                                        src={userData?.profile_picture}
                                        alt=""
                                    />
                                ) : (
                                    <>{`${userData?.account_name?.charAt(0)}`}</>
                                )}
                            </Avatar>
                            <div
                                className={Styles["gs-profile-text-wrapper-poopover-gs-pover-text"]}
                            >
                                <span className={Styles["gs-profile-name-popover"]}>
                                    {
                                        // getAccountByTokenAccountId?.getAccountByTokenAccountId?.data
                                        //   ?.account_name
                                        userData?.account_name
                                    }
                                </span>
                                {/* <span className={Styles["gs-profile-company-name-popover"]}>
                                    {
                                        getAccountByTokenAccountId?.getAccountByTokenAccountId?.data
                                            ?.email_id
                                    }
                                </span> */}
                                {roles?.length > 1 ? (
                                    <Dropdown
                                        popoverClassName={Styles["gs-switch-role-dropdown"]}
                                        placeholder="-- Selected --"
                                        value={userData?.role_id}
                                        onChange={(e) => {
                                            handleRoleChange(e);
                                        }}
                                        sx={{
                                            marginTop: "10px",
                                            height: "35px!important",
                                            minWidth: "165px",
                                        }}
                                        backgroundColor={"white"}
                                    >
                                        {roles?.getAllAssignedRoles?.data === null
                                            ? {}
                                            : roles?.map((item) => {
                                                  return (
                                                      <Dropdown.Item
                                                          key={item?.role_id}
                                                          value={item?.role_id}
                                                      >
                                                          {item?.roles?.role_name}
                                                      </Dropdown.Item>
                                                  );
                                              })}
                                    </Dropdown>
                                ) : (
                                    []
                                )}
                            </div>
                        </div>
                        {/* {getAccountDetail?.map((item, index) => {
              return (
                <div
                  id={index}
                  className={Styles["gs-account-wrapper"]}
                  key={index}
                  onClick={() => handleSwitchAccount(item.account_id)}
                >
                  <Avatar
                    // alt="Remy Sharp"
                    // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${userData?.profile_picture}`}
                    // src="/image/svg/UserloveTextLogo.svg"
                    sx={{
                      width: 34,
                      height: 34,
                      textTransform: "uppercase",
                      backgroundColor:
                        item?.account?.logo === null
                          ? "var(--primary-color) "
                          : "none",
                      color: "var(--white-color)",
                      fontSize: "14px",
                    }}
                    height={"auto"}
                    className="gs-avatar-profile-panel-account"
                    variant="rounded"
                  >
                    {item?.account?.logo !== null ? (
                      <Image
                        height={"auto"}
                        width={"34px"}
                        src={item?.account?.logo}
                        alt=""
                      ></img>
                    ) : (
                      <>{`${item?.account?.account_name.charAt(0)}`}</>
                    )}
                  </Avatar>
                  <div className={Styles["gs-compnay-text-wrapper"]}>
                    <span className={Styles["gs-account-heading"]}>
                      {item?.account?.account_name}
                    </span>
                    <span className={Styles["gs-account-subheading"]}>
                      {item.role}
                    </span>
                  </div>
                </div>
              );
            })} */}

                        {buttonObj.map((item, index) => {
                            return (
                                <div className={Styles["gs-button-wrapper"]} key={index}>
                                    <Button
                                        key={index}
                                        startIcon={item.icons}
                                        className={`gs-secondary-other-ghost-button  ${Styles["gs-action-button"]} extraLarge-button`}
                                        onClick={() => navigate(item.routes1)}
                                    >
                                        {item.text}
                                    </Button>
                                </div>
                            );
                        })}
                        <Button
                            startIcon={<ArrowRightFromBracketIcon className="danger-icon" />}
                            className={`gs-secondary-other-ghost-button  ${Styles["gs-action-button-logout-button"]} extraLarge-button`}
                            onClick={handleLogoutSubmit}
                        >
                            Logout
                        </Button>
                    </div>
                </Popover>
            </Button>
        </>
    );
};
