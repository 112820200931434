import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./Table.module.css";
import { Image } from "../S3Assets";

const handleError = (e) => {
    if (e) {
        return "not found";
    }
};

const Image1 = (props) => {
    // const dynamicStyles = {
    //     // width: props.width || "35px",
    //     height: props.height || "35px",
    // };
    return (
        <>
            <Image
                className={`${styles["gs-image"]} ${props.imageClassName} `}
                src={props.src}
                alt="Not Found"
                onError={handleError}
                // style={dynamicStyles}
                width={props.width || "30px"}
                height={props.height || "30px"}
            />
        </>
    );
};
const getText = (text) => {
    const textArray = text?.split(" ");
    if (textArray?.length > 1) {
        return `${textArray[0]?.charAt(0)}${textArray[1]?.charAt(0)}`.toUpperCase();
    } else {
        return textArray[0]?.charAt(0).toUpperCase();
    }
};
const Image2 = (props) => {
    return (
        <>
            <Avatar
                className={styles["gs-avatar-img"]}
                src={props?.src}
                sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: "var(--primary-color)",
                    fontSize: "15px",
                }}
            >
                {getText(props?.primaryText)}
            </Avatar>
        </>
    );
};

const components = {
    image: Image1,
    avatar: Image2,
};

export const TableData = (props) => {
    const {
        primaryText = null,
        secondaryText = null,
        captionText = null,
        url = null,
        srcType = "image",
        onClick,
        showImage = false,
        primaryTextColor = null,
        primaryClassName,
        imageClassName,
        src = null,
        text = null,
    } = props;

    // if (!primaryText) throw new Error("Primary Text not defined");
    const Component = components[srcType];

    return (
        <Stack alignItems="center" justifyContent="center" direction={"row"} gap="10px">
            {showImage ? (
                <Component
                    src={url}
                    alt="Not Found"
                    primaryText={primaryText}
                    imageClassName={imageClassName}
                />
            ) : null}
            <Stack>
                <Typography>
                    <a
                        style={{
                            color: primaryTextColor,
                        }}
                        className={`${styles["gs-table-primary-text"]} ${primaryClassName}`}
                        onClick={onClick}
                        data-click-allow={Boolean(onClick)}
                        href
                    >
                        {primaryText}
                    </a>
                </Typography>

                {secondaryText ? (
                    <Typography className={styles["gs-table-secondary-text"]}>
                        {secondaryText}
                    </Typography>
                ) : null}
                {captionText ? (
                    <Stack
                        display={"flex"}
                        flexDirection={"row"}
                        className={styles["gs-table-caption-text"]}
                    >
                        <Typography>{captionText}</Typography>
                        <Typography display={"flex"} gap={"5px"}>
                            <div style={{ height: "20px", width: "20px" }}>
                                <Image src={src} />
                            </div>
                            {text}
                        </Typography>
                    </Stack>
                ) : null}
            </Stack>
        </Stack>
    );
};
